import styled from 'styled-components';

import { IInterUIProgressBarProps } from '../../interfaces/inter-ui-progress-bar-props';

/**
 * Div referente a barra.
 */
export const Bar = styled.div<IInterUIProgressBarProps>`
  flex: 1;
  height: ${(props) => props.height || '2px'};
  ${(props) => props.margin && `margin: ${props.margin};`}
  background-color: ${(props) =>
    props.barColor || props.theme.colors.neutral.grayscale.A100};

  ${(props) => props.rounded && `border-radius: 50px;`}
`;

/**
 * Div referente ao progresso.
 */
export const Progress = styled.span<IInterUIProgressBarProps>`
  display: block;
  width: ${(props) => props.progress || `0%`};
  height: 100%;
  background-color: ${(props) =>
    props.progressColor || props.theme.colors.primary.A500};
  transition: width 0.5s;

  ${(props) => props.rounded && `border-radius: 50px;`}
`;
